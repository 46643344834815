import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import SearchBlock from "components/search/SearchBlock";
import { autobind } from "react-decoration";
import { page } from "components/page";

import LocalNewsListComp from "components/resource/news/LocalNewsListComp";
import RemoteNewsListComp from "components/resource/news/RemoteNewsListComp";

import navigate from "lib/navigate";
import qs from "query-string";

@withI18next(["common"])
@page
class NewsList extends React.Component {
  constructor(props) {
    super(props);
    let state = this.getQueryState(props);
    this.state = state;
  }

  componentWillReceiveProps(props) {
    if (this.props !== props) {
      this.setQueryState(props);
    }
  }

  @autobind
  setQueryState(props) {
    let state = this.getQueryState(props);

    if (JSON.stringify(state) !== JSON.stringify(this.state)) {
      this.setState({
        ...state,
      });
    }
  }

  @autobind
  getQueryState(props) {
    let { search, readerStore } = props;
    let params = { ...search };
    let { pageNo, limit, resourceType, newsType } = params;

    pageNo = pageNo || 1;
    limit = limit || 10;

    newsType = newsType || "local";

    let searchForm = {
      pageNo,
      limit,
      resourceType,
      newsType,
    };

    //console.log("1111111111111");
    //console.log(newsType);
    //console.log(searchForm);
    //console.log("2222222222222");

    Object.keys(searchForm).map((key) => {
      if (
        searchForm[key] === null ||
        (typeof searchForm[key] === "string" && searchForm[key].trim() === "")
      ) {
        delete searchForm[key];
      }
    });

    let state = {
      newsType: newsType,
      searchForm: searchForm,
      auth: readerStore.auth,
      resourceType: resourceType,
    };

    return state;
  }

  @autobind
  changeNewsType(newsType, e) {
    e.preventDefault();
    let { search, searchForm, location } = this.props;
    let params = {};

    params["newsType"] = newsType;

    //console.log("changeNewsType().newsType="+newsType);

    //let test = this.state.searchForm;
    //test.newsType = newsType;

    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  newsListComp() {
    //console.log("newsListComp()="+this.state.newsType);
    if (this.state.newsType !== undefined) {
      switch (this.state.newsType) {
        case "local":
          return (
            <LocalNewsListComp
              searchForm={this.state.searchForm}
              resourceType={this.state.resourceType}
              newsType={this.state.newsType}
              {...this.props}
            />
          );
        case "remote":
          return (
            <RemoteNewsListComp
              searchForm={this.state.searchForm}
              resourceType={this.state.resourceType}
              newsType={this.state.newsType}
              {...this.props}
            />
          );
      }
    }
  }

  render() {
    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <SearchBlock />
          <section className="main_content">
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accesskey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <h2>{this.props.t("jumper.common.news.titlebar")}</h2>
              <a href="javascript:history.back();" className="back">
                {this.props.t("hyint.common.table.back")}
              </a>

              <div className="tabSet">
                <ul className="tabs">
                  <li
                    className={
                      this.state.newsType === "remote" ? "active" : ""
                    }>
                    <a
                      tabIndex="0"
                      onClick={this.changeNewsType.bind(this, "remote")}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.changeNewsType("remote", e);
                        }
                      }}>
                      {this.props.t("jumper.common.rssnews")}
                    </a>
                  </li>
                  <li
                    className={this.state.newsType === "local" ? "active" : ""}>
                    <a
                      tabIndex="0"
                      onClick={this.changeNewsType.bind(this, "local")}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.changeNewsType("local", e);
                        }
                      }}>
                      {this.props.t("jumper.common.news.titlebar")}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="detail_block">{this.newsListComp()}</div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default NewsList;
