import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import SearchBlock from "components/search/SearchBlock";
import { autobind } from "react-decoration";
import { page } from "components/page";
import gql from "graphql-tag";
import GraphqlFragment from "lib/GraphqlFragment";
import DataList from "components/list/DataList";
import NewsDisplayComp from "components/resource/NewsDisplayComp";
import NewsLayout from "components/NewsLayout";
import navigate from "lib/navigate";
import qs from "query-string";

const getNewsList = gql`
  query getNewsList($searchForm: NewsForm){
    getNewsList(Input: $searchForm){
      ${GraphqlFragment.defaultLayoutReturn}
    }
  }
  ${GraphqlFragment.defaultLayoutFragment} 
`;

@withI18next(["common"])
@page
class LocalNewsListComp extends React.Component {
  constructor(props) {
    super(props);
    //let state = this.getQueryState(props);
    //this.state = state;
  }

  render() {
    let {search, appStore, searchForm,newsType, t} = this.props;
    //console.log("newsType="+search.newsType);

    console.log("===LocalNewsListComp start===");
    console.log(searchForm);
    console.log("this.props.newsType="+newsType);
    console.log("===LocalNewsListComp end===");

    return (
      <DataList
        query={getNewsList}
        variables={{
          searchForm: searchForm,
        }}
        fetchPolicy={"network-only"}
        displayComp={NewsDisplayComp}
        pagination={"FixedPagination"}
        customComponentLayout={NewsLayout}
      />    
    );
  }
}

export default LocalNewsListComp;
